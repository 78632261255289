import { Contexto } from '../environment.types';

export const wyden: Contexto[] = [
  {
    host: 'http://localhost:3003',
    portal: 'https://dev.portal.wyden.com.br/experimente',
    home: 'https://dev.portal.wyden.com.br/',
    env: 'local',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    brand: 'wyden',
    candidato: 'https://candidatos.dev.portal.wyden.com.br/login',
  },
  {
    host: 'https://experimente.dev.wyden.com.br',
    portal: 'https://dev.portal.wyden.com.br/experimente',
    home: 'https://dev.portal.wyden.com.br/',
    env: 'dev',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    brand: 'wyden',
    candidato: 'https://candidatos.dev.portal.wyden.com.br',
  },
  {
    host: 'https://experimente.qa.wyden.com.br',
    portal: 'https://qa.portal.wyden.com.br/experimente',
    home: 'https://qa.portal.wyden.com.br/',
    env: 'qld',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    brand: 'wyden',
    candidato: 'https://candidatos.qa.portal.wyden.com.br',
  },
  {
    host: 'https://experimente.wyden.com.br',
    portal: 'https://wyden.com.br/experimente',
    home: 'https://wyden.com.br/',
    env: 'prd',
    savaEstudante: 'https://estudante.wyden.com.br',
    brand: 'wyden',
    candidato: 'https://candidatos.portal.wyden.com.br',
  },
];
