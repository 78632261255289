import { LftSkeleton, LftTypography } from '@lift/design-system-react-web/dist/components';
import LftLogotipo from '@lift/design-system-react-web/dist/components/LftLogotipo';
import { useStore } from 'effector-react';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCursos } from '../../hooks/cursos/cursos.hook';
import { useResponsividade } from '../../hooks/responsividade/responsividade.hook';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { Curso } from '../../models/usuario.model';
import analyticsService from '../../services/analytics/analytics.service';
import { useCurso } from '../../stores/curso/curso.hook';
import { atualizaCurso } from '../../stores/curso/curso.store';
import { $listaCurso } from '../../stores/lista-curso/lista-curso.store';
import { atualizaLoadingCursos } from '../../stores/loading-curso/loading-cursos.store';
import { useUsuario } from '../../stores/usuario/usuario.hook';
import { $isLead, usuarioAtualizado, usuarioMigrado } from '../../stores/usuario/usuario.store';
import { capitalizeFormatter } from '../../utils/formatters/capitalize/capitalize.formatter';
import { getTipoCurso, transformarTipoCurso } from '../../utils/redirect-home/redirect-home.types';
import { Header } from '../header/header.component';
import { InputSearch } from '../input-search/input-search.component';
import { MenuAcessibilidade } from '../menu-acessibilidade';
import { MenuAvatar } from '../menu-avatar/menu-avatar.component';
import MenuSanduiche from '../menu-sanduiche/menu-sanduiche.component';
import ScreenError from '../screen-error';
import Sidebar from '../sidebar/sidebar.component';
import S from './app-container.styles';
import { Modalidade } from './app-container.types';
import { useUsuarioDatalayer } from './hooks/usuario-datalayer/usuario-datalayer';

const { brand } = ConfigsManager.getInstance();

export const AppContainer: React.FC = () => {
  const [renderConteudo, setRenderConteudo] = useState(true);

  useUsuarioDatalayer();
  const { pathname } = useLocation();
  const isDisciplinasHome = pathname === '/disciplinas';
  const { erro: erroUsuario } = useUsuario();
  const { curso } = useCurso();
  const { desktop } = useResponsividade();
  const isLead = useStore($isLead);
  const listaCurso = useStore($listaCurso);
  const { erro: erroCursos, pendente: cursosPendente } = useCursos();
  atualizaLoadingCursos(cursosPendente);

  const DEFAULT_TIPO_CURSO = 'GRAD';
  const cursoSelecionadoLocal = localStorage.getItem('cursoSelecionado');
  const [tagSelecionada, setTagSelecionada] = useState(DEFAULT_TIPO_CURSO);
  const [pathnameReal, setPathnameReal] = useState<string | null>(null);
  const tipoCursoRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (pathname !== '/') {
      setPathnameReal(pathname);
    }
  }, [pathname]);

  const pushPageLoadedDatalayer = useCallback((pathname: string, tipoCursoRef: string | undefined) => {
    analyticsService.pageLoaded({ path: pathname, brand, type: tipoCursoRef });
  }, []);

  const atualizaTipoCursoCandidato = useCallback(
    (pathnameReal: string) => {
      if (tipoCursoRef.current) {
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      } else {
        return usuarioAtualizado.watch((usuario) => {
          const tipoCurso = usuario?.curso?.tipo ? transformarTipoCurso[usuario?.curso?.tipo] : undefined;
          tipoCursoRef.current = tipoCurso ? getTipoCurso[tipoCurso] : undefined;
          pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLead, pushPageLoadedDatalayer],
  );

  useEffect(() => {
    if (pathnameReal) {
      if (isLead) {
        tipoCursoRef.current = curso?.tipo ? getTipoCurso[curso.tipo] : undefined;
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      }
      atualizaTipoCursoCandidato(pathnameReal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atualizaTipoCursoCandidato, isLead, pathnameReal, pushPageLoadedDatalayer]);

  useEffect(() => {
    if (isLead && cursoSelecionadoLocal) {
      setTagSelecionada(JSON.parse(cursoSelecionadoLocal).tipo || DEFAULT_TIPO_CURSO);
    }
  }, [isLead, cursoSelecionadoLocal]);

  const selecionarCurso = (item: Curso) => {
    if (isLead && item && !isEqual(item, JSON.parse(cursoSelecionadoLocal!))) {
      localStorage.setItem('cursoSelecionado', JSON.stringify(item));
      atualizaCurso(item);
    }
  };

  const ordemTipos: Modalidade[] = [Modalidade.GRAD, Modalidade.POS, Modalidade.TEC];
  const tiposCompletos: Record<string, { label: string; value: string }> = {
    [Modalidade.GRAD]: { label: 'Graduação', value: 'GRAD' },
    [Modalidade.POS]: { label: 'Pós-Graduação', value: 'POS' },
    [Modalidade.TEC]: { label: 'Técnico', value: 'TEC' },
  };

  const tiposUnicos = Array.isArray(listaCurso)
    ? ordemTipos.filter((tipo) => listaCurso.some((curso) => curso.tipo === tipo))
    : [];

  const tagPropsMapeadas = tiposUnicos.map((tipo) => ({
    id: `Tag${tiposUnicos.indexOf(tipo) + 1}`,
    label: tiposCompletos[tipo]?.label,
    value: tiposCompletos[tipo]?.value,
    name: 'type-select',
  }));

  const capitalizeListaCurso = (titulo: string): string => {
    const preposicoes = new Set(['de', 'da', 'do', 'das', 'dos', 'em', 'por', 'a', 'e']);
    return titulo
      .toLowerCase()
      .split(' ')
      .map((palavra, index) =>
        index === 0 || !preposicoes.has(palavra) ? palavra.charAt(0).toUpperCase() + palavra.slice(1) : palavra,
      )
      .join(' ');
  };

  const listaCursoFiltrada = () => {
    const listaFiltrada = listaCurso?.filter((curso) => curso.tipo === tagSelecionada);
    const listaCapitalizada = listaFiltrada.map((lista) => ({
      ...lista,
      nome: capitalizeListaCurso(lista.nome),
    }));

    return listaCapitalizada.sort((a, b) => a.nome.localeCompare(b.nome));
  };

  useEffect(() => {
    const subscription$ = usuarioMigrado.watch(() => setRenderConteudo(false));
    return () => subscription$.unsubscribe();
  }, []);

  if (erroUsuario && erroUsuario.name !== 'NoContentException') {
    return <ScreenError.Fatal erro="ERRO_CARREGAR_DADOS_USUARIO" />;
  }

  if (erroCursos) {
    return <ScreenError.Fatal erro="ERRO_CARREGAR_CURSOS" />;
  }

  if (renderConteudo) {
    return (
      <S.AppContainer>
        {desktop && (
          <S.SidebarContainer data-testid="sidebar">
            <Sidebar />
          </S.SidebarContainer>
        )}
        <S.MainContainer>
          <Header
            logo={
              <Link to="/">
                <LftLogotipo alt="Logotipo da marca" />
              </Link>
            }
            center={
              <>
                {isLead && isDisciplinasHome && (
                  <>
                    {listaCurso?.length ? (
                      <InputSearch<Curso>
                        tag={{
                          tagPropsMapeadas,
                          tagSelecionada,
                          setTagSelecionada,
                        }}
                        inputSearch={{
                          placeholder: 'Buscar curso',
                          className: 'listaCursos',
                          dataElement: 'selecionar-curso',
                          dataSection: 'section_busca-cursos',
                        }}
                        listaMenu={{
                          itensFiltrados: listaCursoFiltrada(),
                          atualizaItem: (item) => selecionarCurso(item),
                        }}
                      />
                    ) : (
                      <S.SkeletonContainer className="skeleton-container">
                        <LftSkeleton shape="square" id="skeleton-lista-curso" height="3.5rem" />
                      </S.SkeletonContainer>
                    )}
                  </>
                )}
                {!isLead && desktop && (
                  <S.CursoWrapper>
                    {curso ? (
                      <>
                        <LftTypography component="caption">Curso</LftTypography>
                        <LftTypography component="paragraph" fontWeight="medium">
                          {capitalizeFormatter(curso.nome)}
                        </LftTypography>
                      </>
                    ) : (
                      <LftSkeleton shape="line" id="skeleton-nome-curso" />
                    )}
                  </S.CursoWrapper>
                )}
              </>
            }
            aside={
              desktop ? (
                <S.HeaderAsideContainer>
                  <MenuAcessibilidade dropdownPosition="bottom" />
                  <MenuAvatar />
                </S.HeaderAsideContainer>
              ) : (
                <MenuSanduiche />
              )
            }
          />
          <Outlet />
        </S.MainContainer>
      </S.AppContainer>
    );
  }

  return <Navigate to="/disciplinas" />;
};
