import { BrowserCacheLocation } from '@azure/msal-browser';
import { IAuthConfig } from '../auth.types';
import { loggerCallback } from '../logger/logger';

export const wyden: IAuthConfig[] = [
  {
    envs: ['local'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '567b6a3f-5bfe-4abb-93ca-496d0c5cacf6',
          authority: 'https://login.dev.wyden.com.br/wydendev.onmicrosoft.com/B2C_1_sava_candidato_sigin',
          knownAuthorities: ['login.dev.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydendev.onmicrosoft.com/567b6a3f-5bfe-4abb-93ca-496d0c5cacf6/user.read',
      },
    },
    applicationUrl: 'http://localhost:3003',
  },
  {
    envs: ['local'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'c06507c4-282a-4a96-bd83-60f1845a0243',
          authority: 'https://login.dev.wyden.com.br/wydendev.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.dev.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydendev.onmicrosoft.com/c06507c4-282a-4a96-bd83-60f1845a0243/user.read',
      },
    },
    applicationUrl: 'http://localhost:3007',
  },
  {
    envs: ['dev'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '567b6a3f-5bfe-4abb-93ca-496d0c5cacf6',
          authority: 'https://login.dev.wyden.com.br/wydendev.onmicrosoft.com/B2C_1_sava_candidato_sigin',
          knownAuthorities: ['login.dev.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydendev.onmicrosoft.com/567b6a3f-5bfe-4abb-93ca-496d0c5cacf6/user.read',
      },
    },
    applicationUrl: 'https://candidato.wyden.sava.dev.yduqs.com.br',
  },
  {
    envs: ['dev'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'c06507c4-282a-4a96-bd83-60f1845a0243',
          authority: 'https://login.dev.wyden.com.br/wydendev.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.dev.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydendev.onmicrosoft.com/c06507c4-282a-4a96-bd83-60f1845a0243/user.read',
      },
    },
    applicationUrl: 'https://experimente.dev.wyden.com.br',
  },
  {
    envs: ['qld'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'b9b13bcf-a24a-47da-bf06-cd07a52e47ae',
          authority: 'https://login.qa.wyden.com.br/wydenqas.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.qa.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydenqas.onmicrosoft.com/b9b13bcf-a24a-47da-bf06-cd07a52e47ae/user.read',
      },
    },
    applicationUrl: 'https://candidato.wyden.sava.qld.yduqs.com.br',
  },
  {
    envs: ['qld'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'd8bf8eeb-fc0a-49d6-a968-b8f528e0cc79',
          authority: 'https://login.qa.wyden.com.br/wydenqas.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.qa.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://wydenqas.onmicrosoft.com/d8bf8eeb-fc0a-49d6-a968-b8f528e0cc79/user.read',
      },
    },
    applicationUrl: 'https://experimente.qa.wyden.com.br',
  },
  {
    envs: ['prd'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'e3feace9-8ced-4af3-a7ee-17e9b100025e',
          authority: 'https://login.wyden.com.br/loginwyden.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://loginwyden.onmicrosoft.com/e3feace9-8ced-4af3-a7ee-17e9b100025e/user.read',
      },
    },
    applicationUrl: 'https://candidatos.sava.wyden.com.br',
  },
  {
    envs: ['prd'],
    brands: ['wyden'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'c4fe39f6-f78a-41a6-9711-8c41ecd83c82',
          authority: 'https://login.wyden.com.br/loginwyden.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.wyden.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://loginwyden.onmicrosoft.com/c4fe39f6-f78a-41a6-9711-8c41ecd83c82/user.read',
      },
    },
    applicationUrl: 'https://experimente.wyden.com.br',
  },
];
