import { Assert } from '@aura/core-lib';
import { useStore } from 'effector-react';
import { combineEvents } from 'patronum';
import { useCallback, useEffect } from 'react';
import { codigosCursosAtualizadosEvent } from '../../../../hooks/codigos-cursos-complementares/codigos-cursos-complementares.hook';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { IdExterno } from '../../../../models/usuario.model';
import analyticsService from '../../../../services/analytics/analytics.service';
import { $curso, atualizaCurso, atualizaDisciplinas } from '../../../../stores/curso/curso.store';
import { $credenciais, $isLead, usuarioAtualizado } from '../../../../stores/usuario/usuario.store';
import { Modalidade, obterModalidade } from '../../../../utils/cursos-complementares/cursos-complementares';
import { converterParaKebabCase } from '../../../../utils/formatters/converter-string/converter-string';
import { getTipoCurso } from '../../../../utils/redirect-home/redirect-home.types';
import {
  BaseEvents,
  CursoSelecionado,
  PushDadosUsuarioDatalayerParams,
  RegistrationDataEvents,
  TiposConteudo,
} from './usuario-datalayer.types';

export const useUsuarioDatalayer = () => {
  const idExterno = useStore($credenciais);
  Assert.nonNullable(idExterno);
  const { brand } = ConfigsManager.getInstance();
  const isLead = useStore($isLead);
  const curso = useStore($curso);

  let usuarioId: string;
  let cursoSelecionado: CursoSelecionado;

  const obterTipoConteudo = (possuiDisciplinas: boolean, possuiCursosComplementares: boolean) => {
    if (possuiDisciplinas) {
      return possuiCursosComplementares ? TiposConteudo.DISCIPLINAS_E_CURSOS_COMPLEMENTARES : TiposConteudo.DISCIPLINAS;
    }

    return possuiCursosComplementares ? TiposConteudo.CURSOS_COMPLEMENTARES : TiposConteudo.NENHUM;
  };

  const pushDadosUsuarioDatalayerNivel1 = useCallback((idExterno: IdExterno) => {
    analyticsService.registrationDataN1({
      inscricao: {
        usuarioLead: isLead,
        idInscricao: idExterno.atila,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushDadosUsuarioDatalayer = useCallback((params: PushDadosUsuarioDatalayerParams) => {
    analyticsService.registrationData({
      inscricao: {
        idLead: isLead ? (params.usuario?.id ?? usuarioId) : undefined,
        idInscricao: idExterno?.atila,
        tipoCurso: params.usuario?.curso?.tipo ?? cursoSelecionado?.tipoCurso,
        curso: params.usuario?.curso?.nome ?? cursoSelecionado?.nomeCurso,
        modalidade: isLead
          ? converterParaKebabCase(obterModalidade(cursoSelecionado?.modalidade as Modalidade))
          : converterParaKebabCase(params.usuario?.curso?.modalidade),
        nomInstituicao: brand,
      },
      matricula: {
        codMatricula: params.usuario?.matriculaAcademica?.codigo,
      },
      conteudo: {
        tipoConteudo: obterTipoConteudo(params.possuiDisciplinas, params.possuiCursosComplementares),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registrarNivel2 = useCallback(() => {
    const baseEvents: BaseEvents = {
      usuario: usuarioAtualizado,
      codigosCursos: codigosCursosAtualizadosEvent,
    };

    const events: RegistrationDataEvents = isLead
      ? { ...baseEvents, gradeCurricular: atualizaDisciplinas }
      : baseEvents;

    const $registrationDataEvents = combineEvents({
      events,
    });

    const watcher = $registrationDataEvents.watch((payload) => {
      const cursoSelecionadoLocal = localStorage.getItem('cursoSelecionado');
      if (isLead && cursoSelecionadoLocal) {
        const parsedSession = JSON.parse(cursoSelecionadoLocal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cursoSelecionado = {
          nomeCurso: converterParaKebabCase(parsedSession.nome),
          tipoCurso: getTipoCurso[parsedSession.tipo],
          modalidade: parsedSession.modalidade,
          disciplinas: payload?.gradeCurricular?.disciplinas,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        usuarioId = payload.usuario.id;
      }

      pushDadosUsuarioDatalayer({
        idExterno,
        usuario: payload.usuario,
        possuiDisciplinas: isLead
          ? (payload?.gradeCurricular?.disciplinas?.length ?? 0) > 0
          : (payload.usuario.curso?.disciplinas?.length ?? 0) > 0,
        possuiCursosComplementares: payload.codigosCursos?.length > 0,
      });
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayer]);

  const registrarNivel3 = useCallback(() => {
    const events = {
      gradeCurricular: atualizaDisciplinas,
      codigosCursos: codigosCursosAtualizadosEvent,
      curso: atualizaCurso,
    };

    const $registrationDataEvents = combineEvents({
      events,
    });

    const watcher = $registrationDataEvents.watch((payload) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cursoSelecionado = {
        nomeCurso: converterParaKebabCase(payload?.curso?.nome),
        tipoCurso: getTipoCurso[payload?.curso?.tipo],
        modalidade: payload?.curso?.modalidade,
        disciplinas: payload?.gradeCurricular?.disciplinas,
      };

      pushDadosUsuarioDatalayer({
        idExterno,
        possuiDisciplinas: (payload?.gradeCurricular?.disciplinas?.length ?? 0) > 0,
        possuiCursosComplementares: payload.codigosCursos?.length > 0,
      });
    });

    return { desinscrever: () => watcher() };
  }, [idExterno, pushDadosUsuarioDatalayer]);

  useEffect(() => {
    pushDadosUsuarioDatalayerNivel1(idExterno);

    const watcherNivel2 = registrarNivel2();

    return () => {
      watcherNivel2.desinscrever();
    };
  }, [idExterno, pushDadosUsuarioDatalayerNivel1, registrarNivel2]);

  useEffect(() => {
    const watcherNivel3 = registrarNivel3();
    return () => {
      watcherNivel3.desinscrever();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curso?.nome]);
};
