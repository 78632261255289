import { BrowserCacheLocation } from '@azure/msal-browser';
import { IAuthConfig } from '../auth.types';
import { loggerCallback } from '../logger/logger';

export const idomed: IAuthConfig[] = [
  {
    envs: ['local'],
    brands: ['idomed'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'ee10a247-0479-4b28-9935-34660c652b89',
          authority: 'https://login.dev.idomed.com.br/idomeddev.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.dev.idomed.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://idomeddev.onmicrosoft.com/ee10a247-0479-4b28-9935-34660c652b89/user.read',
      },
    },
    applicationUrl: 'http://localhost:3004',
  },
  {
    envs: ['dev'],
    brands: ['idomed'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'ee10a247-0479-4b28-9935-34660c652b89',
          authority: 'https://login.dev.idomed.com.br/idomeddev.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.dev.idomed.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://idomeddev.onmicrosoft.com/ee10a247-0479-4b28-9935-34660c652b89/user.read',
      },
    },
    applicationUrl: 'https://experimente.dev.idomed.com.br',
  },
  {
    envs: ['qld'],
    brands: ['idomed'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'c2cf9bd2-22e4-42ba-b8f0-fcf52f06038d',
          authority: 'https://login.qa.idomed.com.br/idomedqas.onmicrosoft.com/B2C_1_signin',
          knownAuthorities: ['login.qa.idomed.com.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://idomedqas.onmicrosoft.com/c2cf9bd2-22e4-42ba-b8f0-fcf52f06038d/user.read',
      },
    },
    applicationUrl: 'https://experimente.qa.idomed.com.br',
  },
];
