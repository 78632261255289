import { Assert } from '@aura/core-lib';
import { Contexto } from './environment.types';
import { estacio } from './marcas/estacio';
import { ibmec } from './marcas/ibmec';
import { idomed } from './marcas/idomed';
import { wyden } from './marcas/wyden';
import { yduqs } from './marcas/yduqs';

const contextos = (): Contexto[] => [...yduqs, ...estacio, ...wyden, ...ibmec, ...idomed];

const redirecionaSeNecessario = (contexto: Contexto) => {
  if (contexto.redirect) {
    window.location.href = `${contexto.redirect}${window.location.search}`;
  }
};

// identificação do ambiente e da marca estão atreladas à URL na qual a aplicação está sendo acessada
const contexto = contextos().find((ctx) => ctx.host === `${window.location.protocol}//${window.location.host}`);
Assert.nonNullable(contexto, () => 'contexto de aplicação indefinido');

// caso haja um problema na identificação do contexto será disparada uma exception
const env = contexto.env;
Assert.nonNullable(contexto.env, () => 'ambiente indefinido');

// Caminho para o portal da marca e ambiente identificado
const portal = contexto.portal;
Assert.nonNullable(portal, () => 'URL do portal indefinida');

// Caminho para a home da marca e ambiente identificado
const home = contexto.home;
Assert.nonNullable(home, () => 'URL da home indefinida');

// Caminho para o portal cvc da marca e ambiente identificado
const savaEstudante = contexto.savaEstudante;
Assert.nonNullable(savaEstudante, () => 'URL do savaEstudante indefinida');

// Url base da aplicação
const host = contexto.host;
Assert.nonNullable(host, () => 'host indefinido');

// Marca utilizada
const brand = contexto.brand;
Assert.nonNullable(brand, () => 'marca indefinida');

// Caminho para o portal cvc da marca e ambiente identificado
const candidato = contexto.candidato;
Assert.nonNullable(portal, () => 'URL do CVC indefinida');

redirecionaSeNecessario(contexto);

export { brand, candidato, env, home, host, portal, savaEstudante };
