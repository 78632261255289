import { CursoComplementar } from '@aura/shared.cursos-complementares-frontend';

export const cursosWyden: CursoComplementar[] = [
  {
    codigo: 'wyden-padrao-1',
    titulo: 'Descomplicando a Língua Portuguesa',
    url: 'https://stecine.azureedge.net/repositorio/00212ge/07640/index.html',
    resumo: 'Revisão de itens gramaticais a partir das dificuldades mais comuns no uso da língua portuguesa',
  },
  {
    codigo: 'wyden-padrao-2',
    titulo: 'Raciocínio Lógico e Resolução de Problemas',
    url: 'https://stecine.azureedge.net/repositorio/00212ge/07641/index.html',
    resumo:
      'Desperte seu potencial com o curso de Raciocínio Lógico e Resolução de Problemas! Aprenda a pensar criticamente e a resolver desafios de forma criativa, equipando-se com habilidades essenciais para o sucesso na carreira tecnológica',
  },
  {
    codigo: 'wyden-padrao-3',
    titulo: 'Empreendedorismo e Inovação',
    url: 'https://stecine.azureedge.net/repositorio/empreendedorismo_e_inovacao/index.html',
    resumo:
      'Novas perspectivas de empreendedorismo e inovação, assim como a importância da reflexão sobre o mercado atual',
  },
];
