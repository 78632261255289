import { Environment } from '../configs.types';
import { EstacioApiMap } from './marcas/estacio/estacio';
import { IbmecApiMap } from './marcas/ibmec/ibmec';
import { IdomedApiMap } from './marcas/idomed/idomed';
import { WydenApiMap } from './marcas/wyden/wyden';
import { YduqsApiMap } from './marcas/yduqs/yduqs';

const apiDev = localStorage.getItem('apiDev') === 'true';
const apiUrl = localStorage.getItem('apiUrl');

const apiMaps = [...EstacioApiMap, ...WydenApiMap, ...IbmecApiMap, ...IdomedApiMap, ...YduqsApiMap];

const apiConfigs = () => {
  let selectedEnv = 'local';
  let selectedBrand = 'yduqs';

  return {
    env: function (env: Environment) {
      selectedEnv = env;
      return this;
    },
    brand: function (brand: string) {
      selectedBrand = brand;
      return this;
    },
    get: function () {
      const apiMarca = apiMaps.filter((api) => api.brand === selectedBrand);

      if (apiDev && ['local', 'ci'].includes(selectedEnv)) {
        if (apiUrl) {
          return {
            api: apiUrl,
            apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
            tempoDedupping: YduqsApiMap[0].tempoDedupping,
          };
        }

        selectedEnv = 'dev';
      }

      const config = apiMarca.find((api) => api.envs.includes(selectedEnv));

      if (config) {
        return { api: config.api, apm: config.apm, tempoDedupping: config.tempoDedupping };
      }

      throw new Error('configuration not found, verify "src/infra/configs/api" directory');
    },
  };
};

export { apiConfigs };
