import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

export enum TiposConteudo {
  NENHUM = '0',
  DISCIPLINAS = 'disciplinas',
  CURSOS_COMPLEMENTARES = 'complementar',
  DISCIPLINAS_E_CURSOS_COMPLEMENTARES = '2',
}

export interface IRegistrationData {
  inscricao: {
    idInscricao?: number | string | null;
    idCandidato?: number | null;
    idLead?: string;
    tipoCurso?: string;
    curso?: string;
    modalidade?: string;
    nomInstituicao?: string;
  };
  matricula?: {
    codMatricula?: string;
  };
  conteudo?: {
    tipoConteudo?: TiposConteudo;
  };
}

export interface IRegistrationDataN1 {
  inscricao: {
    usuarioLead?: boolean | null;
    idInscricao?: number | string | null;
    idCandidato?: number | null;
  };
}

export interface IPageLoaded {
  path: string;
  brand: string;
  type: string | undefined;
}

export interface IDataDisciplina {
  curso: string;
  formacao: string;
  disciplina: string;
}

export interface ILeadDataStart {
  idLead: string | null | undefined;
}

export interface IEnvError {
  code: string;
  description: string;
  isFatal: boolean;
}

export interface IAnalyticsService {
  init(): void;
  registrationData(payload: IRegistrationData): void;
  registrationDataN1(payload: IRegistrationDataN1): void;
  pageLoaded(payload: IPageLoaded): void;
  dataDisciplina(payload: IDataDisciplina): void;
  leadDataStart(payload: ILeadDataStart): void;
  envError(payload: IEnvError): void;
}

class AnalyticsService implements IAnalyticsService {
  init() {
    const tagManagerArgs = {
      gtmId: 'GTM-WDGD7RB',
    };
    TagManager.initialize(tagManagerArgs);
  }

  registrationData(payload: IRegistrationData): void {
    window.dataLayer.push({
      event: 'registration_data', // Nome do evento
      details: {
        inscricao: {
          idInscricao: payload?.inscricao?.idInscricao, // ID da inscrição
          idCandidato: payload?.inscricao?.idCandidato, // ID do candidato
          idLead: payload?.inscricao?.idLead, // ID do lead
          tipoCurso: payload?.inscricao?.tipoCurso, // Tipo do curso
          curso: payload?.inscricao?.curso, // Nome do curso
          modalidade: payload?.inscricao?.modalidade, // Modalidade do curso
          nomInstituicao: payload?.inscricao?.nomInstituicao, // Nome da instituição
        },
        matricula: {
          codMatricula: payload?.matricula?.codMatricula, // Código da matrícula
        },
        conteudo: {
          tipoConteudo: payload?.conteudo?.tipoConteudo, // Tipo de conteúdo
        },
      },
    });
  }

  registrationDataN1(payload: IRegistrationDataN1): void {
    window.dataLayer.push({
      event: 'registration_data', // Nome do evento
      details: {
        inscricao: {
          usuarioLead: payload?.inscricao?.usuarioLead, // Se é um usuário lead
          idInscricao: payload?.inscricao?.idInscricao, // ID da inscrição
          idCandidato: payload?.inscricao?.idCandidato, // ID do candidato
        },
      },
    });
  }

  pageLoaded(payload: IPageLoaded): void {
    window.dataLayer.push({
      event: 'page_loaded', // Nome do evento
      page_details: {
        path: payload.path, // Caminho da página
        brand: payload.brand, // Marca
        type: payload.type, // Tipo
      },
    });
  }

  dataDisciplina(payload: IDataDisciplina): void {
    window.dataLayer.push({
      event: 'data_disciplina', // Nome do evento
      details: {
        curso: payload.curso, // Nome do curso
        formacao: payload.formacao, // Nome da formação
        disciplina: payload.disciplina, // Nome da disciplina
      },
    });
  }

  leadDataStart(payload: ILeadDataStart): void {
    window.dataLayer.push({
      event: 'lead_data_start', // Nome do evento
      lead_data: {
        idLead: payload.idLead, // ID do lead
      },
    });
  }

  envError(payload: IEnvError): void {
    window.dataLayer.push({
      event: 'env_error', // Nome do evento
      error_details: {
        code: payload.code, // Código do erro
        description: payload.description, // Descrição do erro
        is_fatal: payload.isFatal, // Se é fatal
      },
    });
  }
}

export default new AnalyticsService() as IAnalyticsService;
