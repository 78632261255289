import { InteractionStatus } from '@azure/msal-browser';
import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { Loading } from '../../../../components/loading/loading.component';
import { Guard } from '../../../../features/features.types';
import { useAutenticacao } from '../../../../hooks/autenticacao/autenticacao.hook';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { IdExterno } from '../../../../models/usuario.model';
import { $autenticacao } from '../../../../stores/autenticacao/autenticacao.store';
import { autenticado as onUsuarioAutenticado, setIsLead } from '../../../../stores/usuario/usuario.store';
import { handleSearchParamsV2 } from '../../../../utils/handle-search-params-v2/handle-search-params-v2';
import { handleSearchParams } from '../../../../utils/handle-search-params/handle-search-params';

export const AuthGuard: Guard = ({ render, fallback }) => {
  const { isEnabled } = ConfigsManager.getInstance();

  const enabledHandleSearchParamsV2 = isEnabled('FF_HANDLE_SEARCH_PARAMS_V2');
  const {
    loginHint,
    idExterno: searchIdExterno,
    isLead,
    enturmacao,
    origem,
  } = useMemo(
    () => (enabledHandleSearchParamsV2 ? handleSearchParamsV2() : handleSearchParams()),
    [enabledHandleSearchParamsV2],
  );

  const { inProgress, getContaAtiva, acquireToken, signIn, signOut } = useAutenticacao({
    loginHint,
    isLeadParam: isLead,
    idExterno: searchIdExterno,
    enturmacao,
    origem,
  });

  const loading = useMemo(() => <Loading ariaLabel="Carregando página" />, []);

  const idExterno: IdExterno = useMemo(
    () => ({
      atila: searchIdExterno.atila,
      email: loginHint,
    }),
    [loginHint, searchIdExterno],
  );

  const { autenticado, pendente, erro } = useStore($autenticacao);

  // Tratamento do login
  useEffect(() => {
    if (!autenticado && loginHint && InteractionStatus.None === inProgress) {
      const conta = getContaAtiva();
      setIsLead(isLead);

      if (conta) {
        acquireToken();
      } else {
        signIn();
      }
    } else if (!loginHint) {
      signOut();
    }
  }, [acquireToken, autenticado, getContaAtiva, inProgress, isLead, loginHint, signIn, signOut]);

  return useMemo(() => {
    if (pendente) {
      return loading;
    }

    const usuarioAutenticado = autenticado && !pendente && !erro;
    const possuiIdentificacao = isLead || idExterno.atila;

    if (usuarioAutenticado && possuiIdentificacao) {
      const dadosUsuario = isLead ? ({ email: loginHint } as IdExterno) : idExterno;

      onUsuarioAutenticado(dadosUsuario);
      return render;
    }

    localStorage.removeItem('idExterno.atila');
    localStorage.removeItem('origem');

    return fallback;
  }, [pendente, autenticado, erro, isLead, idExterno, fallback, loading, loginHint, render]);
};
