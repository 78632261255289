import { Event } from 'effector';
import { Curso, Disciplina, Usuario } from '../../models/usuario.model';

export enum Modalidade {
  GRAD = 'GRAD',
  POS = 'POS',
  TEC = 'TEC',
}

export interface CursoSelecionado {
  nomeCurso: string;
  tipoCurso: string;
  disciplinas?: Disciplina[];
}

export type BaseEvents = {
  usuario: Event<Usuario>;
  codigosCursos: Event<string[]>;
};

export type RegistrationDataEvents = BaseEvents & {
  gradeCurricular?: Event<Curso>;
};
