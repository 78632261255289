import { Event } from 'effector';
import { Curso, Disciplina, IdExterno, Usuario } from '../../../../models/usuario.model';

export enum TiposConteudo {
  NENHUM = '0',
  DISCIPLINAS = 'disciplinas',
  CURSOS_COMPLEMENTARES = 'complementar',
  DISCIPLINAS_E_CURSOS_COMPLEMENTARES = '2',
}
export interface CursoSelecionado {
  nomeCurso: string;
  tipoCurso: string;
  modalidade: string;
  disciplinas?: Disciplina[];
}

export interface PushDadosUsuarioDatalayerParams {
  idExterno: IdExterno | null | undefined;
  usuario?: Usuario | null;
  possuiDisciplinas: boolean;
  possuiCursosComplementares: boolean;
}

export type BaseEvents = {
  usuario: Event<Usuario>;
  codigosCursos: Event<string[]>;
};

export type RegistrationDataEvents = BaseEvents & {
  gradeCurricular?: Event<Curso>;
};
