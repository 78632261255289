import { Contexto } from '../environment.types';

export const ibmec: Contexto[] = [
  {
    host: 'http://localhost:3002',
    portal: 'https://dev.portal.ibmec.br/experimente',
    home: 'https://dev.portal.ibmec.br/',
    env: 'local',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    brand: 'ibmec',
    candidato: 'https://candidatos.dev.portal.ibmec.br/login',
  },
  {
    host: 'https://experimente.dev.ibmec.br',
    portal: 'https://dev.portal.ibmec.br/experimente',
    home: 'https://dev.portal.ibmec.br/',
    env: 'dev',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    brand: 'ibmec',
    candidato: 'https://candidatos.dev.portal.ibmec.br',
  },
  {
    host: 'https://experimente.qa.ibmec.br',
    portal: 'https://qa.portal.ibmec.br/experimente',
    home: 'https://qa.portal.ibmec.br/',
    env: 'qld',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    brand: 'ibmec',
    candidato: 'https://candidatos.qa.portal.ibmec.br',
  },
  {
    host: 'https://experimente.ibmec.br',
    portal: 'https://ibmec.br/experimente',
    home: 'https://ibmec.br/',
    env: 'prd',
    savaEstudante: 'https://estudante.ibmec.br',
    brand: 'ibmec',
    candidato: 'https://candidatos.portal.ibmec.br',
  },
];
