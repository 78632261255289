import { Contexto } from '../environment.types';

export const idomed: Contexto[] = [
  {
    host: 'http://localhost:3004',
    portal: 'https://dev.portal.idomed.com.br/experimente',
    home: 'https://dev.portal.idomed.com.br/',
    env: 'local',
    savaEstudante: 'https://estudante-sava-idomed.estaciodev.net',
    brand: 'idomed',
    candidato: 'https://candidatos.dev.portal.idomed.com.br/login',
  },
  {
    host: 'https://experimente.dev.idomed.com.br',
    portal: 'https://dev.portal.idomed.com.br/experimente',
    home: 'https://dev.portal.idomed.com.br/',
    env: 'dev',
    savaEstudante: 'https://estudante-sava-idomed.estaciodev.net',
    brand: 'idomed',
    candidato: 'https://candidatos.dev.portal.idomed.com.br',
  },
  {
    host: 'https://experimente.qa.idomed.com.br',
    portal: 'https://qa.portal.idomed.com.br/experimente',
    home: 'https://qa.portal.idomed.com.br/',
    env: 'qld',
    savaEstudante: 'https://estudante-sava-idomed.estaciodev.net',
    brand: 'idomed',
    candidato: 'https://candidatos.qa.portal.idomed.com.br',
  },
  {
    host: 'https://experimente.idomed.com.br',
    portal: 'https://idomed.com.br/experimente',
    home: 'https://idomed.com.br/',
    env: 'prd',
    savaEstudante: 'https://estudante.idomed.com.br',
    brand: 'idomed',
    candidato: 'https://candidatos.portal.idomed.com.br',
  },
];
