import { CursoComplementar } from '@aura/shared.cursos-complementares-frontend';
import { cursosEstacio } from './cursos-marcas/estacio';
import { cursosIbmec } from './cursos-marcas/ibmec';
import { cursosIdomed } from './cursos-marcas/idomed';
import { cursosWyden } from './cursos-marcas/wyden';

const brandsConfig: { [brand: string]: CursoComplementar[] } = {
  estacio: cursosEstacio,
  ibmec: cursosIbmec,
  wyden: cursosWyden,
  idomed: cursosIdomed,
};

const cursosComplementaresConfigs = (brand: string): CursoComplementar[] => {
  const cursosMarca = brandsConfig[brand];

  return cursosMarca ?? [];
};

export { cursosComplementaresConfigs };
