export const IdomedApiMap = [
  {
    envs: ['local', 'ci'],
    api: 'https://apis.candidato.idomed.sava.dev.yduqs.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'idomed',
    tempoDedupping: 1800000,
  },
  {
    envs: ['dev'],
    api: 'https://apis.candidato.idomed.sava.dev.yduqs.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'idomed',
    tempoDedupping: 1800000,
  },
  {
    envs: ['qld'],
    api: 'https://apis.candidato.idomed.sava.qld.yduqs.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'idomed',
    tempoDedupping: 1800000,
  },
  {
    envs: ['prd'],
    api: 'https://apis.candidatos.sava.idomed.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'idomed',
    tempoDedupping: 1800000,
  },
];
