import { Assert } from '@aura/core-lib';
import { Environment } from '../configs.types';
import { AuthConfigManager, IAuthConfig } from './auth.types';
import { estacio } from './marcas/estacio';
import { ibmec } from './marcas/ibmec';
import { idomed } from './marcas/idomed';
import { wyden } from './marcas/wyden';
import { yduqs } from './marcas/yduqs';

const configsMap: IAuthConfig[] = [...idomed, ...wyden, ...ibmec, ...estacio, ...yduqs];

const authConfigs: AuthConfigManager = () => {
  const data: { env: Environment | undefined; brand: string | undefined } = {
    env: undefined,
    brand: undefined,
  };

  return {
    env: function (env: Environment) {
      data.env = env;
      return this;
    },
    brand: function (brand: string) {
      data.brand = brand;
      return this;
    },
    get: function () {
      Assert.nonNullable(data.env);
      Assert.nonNullable(data.brand);

      const env = data.env;
      const brand = data.brand;
      const host = window.location.href;

      const configFound = configsMap.find(
        (config) =>
          config.envs.includes(env) && config.brands.includes(brand) && host.startsWith(config.applicationUrl),
      );

      if (configFound) {
        return configFound;
      }

      throw new Error('configuração não encontrada, verifique o diretório: "src/infra/configs/auth"');
    },
  };
};

export { authConfigs };
