import { DateTime } from 'luxon';
import { ConfigsManager } from '../../../infra/configs/configs.manager';
import { Usuario } from '../../../models/usuario.model';
import { ConsultarListaUsuariosServiceResponse } from '../../../services/consultar-lista-usuarios/consultar-lista-usuarios.types';

const { isEnabled } = ConfigsManager.getInstance();

export const paraUsuario = (r: ConsultarListaUsuariosServiceResponse[]) => {
  if (r.length === 0) {
    return [];
  }

  return r.map(
    (item: ConsultarListaUsuariosServiceResponse): Usuario => ({
      id: item.id,
      idExterno: item.idExterno
        ? {
            atila: item.idExterno.atila,
          }
        : undefined,
      curso: item.curso
        ? {
            codigo: item.curso.codigo,
            nome: item.curso.nome,
            tipo: item.curso.tipo,
            modalidade: item.curso.modalidade,
            periodoIngresso: item.curso.periodoIngresso,
            disciplinas:
              item.curso.disciplinas?.map((disciplina) => ({
                codigo: disciplina.codigo,
                tipoParticipacao: disciplina.tipoParticipacao,
                periodoGrade: disciplina.periodoGrade,
              })) ?? [],
          }
        : undefined,
      email: item.email,
      marca: item.marca,
      nome: item.nome,
      estudanteMigrado: isEnabled('FF_MIGRACAO_ESTUDANTE') ? item.estudanteMigrado : false,
      matriculaAcademica: item.matriculaAcademica
        ? {
            codigo: item.matriculaAcademica.codigo,
            status: item.matriculaAcademica.status,
            email: item.matriculaAcademica.email,
          }
        : undefined,
      dataInscricao: DateTime.fromISO(item.dataInscricao),
      dataAtualizacao: DateTime.fromISO(item.dataAtualizacao),
      dataCriacao: DateTime.fromISO(item.dataCriacao),
    }),
  );
};
