import { IdExterno } from '../../models/usuario.model';

const getCookie = (valor: string) => {
  const cookieValor = document.cookie.split('; ').find((cookie) => cookie.startsWith(`${valor}=`));
  return cookieValor ? JSON.parse(decodeURIComponent(cookieValor.split('=')[1])) : null;
};

const getParams = (searchParams: URLSearchParams) => {
  const getCookies = getCookie('userInfo');
  return {
    loginHint: searchParams.get('login_hint') ?? getCookies?.email,
    logout: !!searchParams.get('signout'),
    atila: searchParams.get('idExterno.atila') ?? getCookies?.inscricao_atila,
    origem: searchParams.get('origem') ?? getCookies?.origem,
    enturmacao: searchParams.get('enturmacao') ?? getCookies?.enturmacao,
  };
};

export const handleSearchParamsV2 = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const { loginHint, logout, atila, origem, enturmacao } = getParams(searchParams);
  const isLead = origem?.toLowerCase() === 'portal';

  const idExterno: IdExterno = {
    atila: isLead ? null : atila,
  };

  return { loginHint, idExterno, logout, isLead, enturmacao, origem };
};
