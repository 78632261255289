import { Contexto } from '../environment.types';

export const estacio: Contexto[] = [
  {
    host: 'http://localhost:3000',
    portal: 'https://dev.portal.estacio.br/experimente',
    home: 'https://dev.portal.estacio.br/',
    env: 'local',
    savaEstudante: 'https://estudante-sava-estacio.estaciodev.net',
    brand: 'estacio',
    candidato: 'https://candidatos.dev.portal.estacio.br/login',
  },
  {
    host: 'https://experimente.dev.estacio.br',
    portal: 'https://dev.portal.estacio.br/experimente',
    home: 'https://dev.portal.estacio.br/',
    env: 'dev',
    savaEstudante: 'https://estudante-sava-estacio.estaciodev.net',
    brand: 'estacio',
    candidato: 'https://candidatos.dev.portal.estacio.br',
  },
  {
    host: 'https://experimente.qa.estacio.br',
    portal: 'https://qa.portal.estacio.br/experimente',
    home: 'https://qa.portal.estacio.br/',
    env: 'qld',
    savaEstudante: 'https://estudante-sava-estacio.estaciodev.net',
    brand: 'estacio',
    candidato: 'https://candidatos.qa.portal.estacio.br',
  },
  {
    host: 'https://experimente.estacio.br',
    portal: 'https://estacio.br/experimente',
    home: 'https://estacio.br/',
    env: 'prd',
    savaEstudante: 'https://estudante.estacio.br',
    brand: 'estacio',
    candidato: 'https://candidatos.portal.estacio.br',
  },
];
